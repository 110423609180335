import { format } from 'date-fns'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import Card from './card'

export default function LatestFourBlogs() {
  const data = useStaticQuery(graphql`
    query {
      allMdx(limit: 4, sort: { fields: frontmatter___date, order: DESC }) {
        nodes {
          slug
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            author
            description
          }
        }
      }
    }
  `)

  return (
    <section
      id="home-news"
      aria-label="News"
      className="mx-auto mt-12 max-w-screen-2xl sm:px-6 lg:px-8"
    >
      <h2 className="text-3xl font-bold text-center text-gray-800 sm:text-4xl md:text-5xl">
        Recent News
      </h2>
      <div className="grid grid-cols-1 gap-8 px-4 mt-8 md:grid-cols-2 md:gap-12">
        {data.allMdx.nodes.map((blog) => {
          return (
            <div key={blog.slug}>
              <Card
                title={format(new Date(blog.frontmatter.date), 'MMMM do, yyyy')}
                btnLabel="Read Article"
                color="secondary"
                to={`/blog/${blog.slug}`}
              >
                <div className="px-3 my-6 bg-white sm:px-6 lg:h-56">
                  <Link
                    to={`/blog/${blog.slug}`}
                    className="text-lg font-bold text-secondary-900 sm:text-xl md:text-2xl md:font-semibold no-underline"
                  >
                    {blog.frontmatter.title}
                  </Link>
                  <p className="mt-2 text-base leading-relaxed sm:mt-6">
                    {blog.frontmatter.description}
                  </p>

                  <div className="mt-4 font-semibold text-gray-900">
                    {blog.frontmatter.author}
                  </div>
                  <div className="mt-1 text-sm text-gray-500">
                    {blog.frontmatter.date}
                  </div>
                </div>
              </Card>
            </div>
          )
        })}
      </div>

      <div className="flex justify-center my-12">
        <Link to="/blog/" className="btn btn-secondary-reverse btn-large">
          See All News{' '}
          <span className="ml-3">
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5 arrow-narrow-right"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </span>
        </Link>
      </div>
    </section>
  )
}
